import React from 'react';
import { useSetRecoilState } from 'recoil';
import { ApplicationAuthState } from '../../@sprinx/react-after-razzle/stateStore';
import { authState, useApiClient } from '../appState';

export interface LoginHandler {
  (username: string, password: string): void;
}

export default function useLoginHandler(
  onSuccess?: (auth: ApplicationAuthState<any>) => void,
  onError?: (err: Error) => void,
): LoginHandler {
  const apiClient = useApiClient();
  const updateAuth = useSetRecoilState(authState);

  return React.useCallback<LoginHandler>(
    (username, password) => {
      apiClient
        .post<Omit<ApplicationAuthState<any>, 'isAuthenticated'>, { password: string; username: string }>(
          '/v1/auth/login',
          {
            username,
            password,
          },
        )
        .then(
          (authData) => {
            const auth = {
              isAuthenticated: true,
              ...authData,
            };

            updateAuth(auth);

            if (onSuccess) onSuccess(auth);
          },
          (err) => {
            if (onError) onError(err);
          },
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiClient, updateAuth],
  );
}
